import { lazy } from 'react'
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './styles/index.scss'

const App  = lazy(() => import('./App'));

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <App />
);

reportWebVitals();
